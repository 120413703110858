<template>
  <nav class="bg-white relative z-[1] flex items-center">
    <div class="container flex justify-between items-center">
      <div class="flex w-full gap-6">
        <a href="https://wordrush.org/">
          <img
            class="logo h-[60px]"
            src="/img/assets/LogoBlue.png"
            alt="Logo"
          />
        </a>
        <a href="https://app.wordrush.org/about">About </a>
        <a href="https://app.wordrush.org/about">Learning Approach </a>
        <!--        <el-dropdown>-->
        <!--          <a> Learning games </a>-->
        <!--          <template #dropdown>-->
        <!--            <el-dropdown-menu>-->
        <!--              <el-dropdown-item>-->
        <!--                <router-link to="/conquest">Simulator game</router-link>-->
        <!--              </el-dropdown-item>-->
        <!--              <el-dropdown-item>-->
        <!--                <router-link to="/terrain-builder">Boarding game</router-link>-->
        <!--              </el-dropdown-item>-->
        <!--              <el-dropdown-item>-->
        <!--                <router-link to="/puzzle-game">Puzzle game</router-link>-->
        <!--              </el-dropdown-item>-->
        <!--              <el-dropdown-item>-->
        <!--                <router-link to="/storyteller">Storyteller game</router-link>-->
        <!--              </el-dropdown-item>-->
        <!--              <el-dropdown-item>-->
        <!--                <router-link to="/writing-game">Writing game</router-link>-->
        <!--              </el-dropdown-item>-->
        <!--            </el-dropdown-menu>-->
        <!--          </template>-->
        <!--        </el-dropdown>-->
      </div>
      <div class="flex hidden-768 text-black" v-if="!isJwt">
        <!--        <a href=" https://app.wordrush.org/learning-science">-->
        <!--          Learning science-->
        <!--        </a>-->
        <router-link to="/sign-up">
          <img height="20" src="/img/icons/Signup.png" alt="" />
          Sign up
        </router-link>
        <router-link to="/sign-in">
          <img height="20" src="/img/icons/Login.png" alt="" />
          Log in
        </router-link>
      </div>
      <div class="flex hidden-768 gap-6 text-black" v-else>
        <button @click="logout">Logout</button>
      </div>
      <button
        @click="isShowSidebar = !isShowSidebar"
        class="text-white text-xl relative z-10 hidden show-768"
      >
        <i class="fa-solid fa-bars text-lg hover:text-gray-50 transition"></i>
      </button>

      <Sidebar :isShowSidebar="isShowSidebar" class="p-2 pt-3">
        <div class="flex text-xl pt-2" v-if="!isJwt">
          <router-link to="/auth?tab=register">
            <img height="20" src="/img/icons/Signup.png" alt="" />
            Sign up
          </router-link>
          <router-link to="/auth">
            <img height="20" src="/img/icons/Login.png" alt="" />
            Log in
          </router-link>
        </div>
        <div class="flex text-xl pt-2" v-else>
          <button @click="logout">Logout</button>
        </div>
      </Sidebar>
    </div>
  </nav>
</template>

<script>
import Sidebar from "@/components/Shared/Sidebar.vue";

export default {
  name: "MyNavbar",
  components: { Sidebar },
  data: () => ({
    width: 1920,
    isShowSidebar: false,
  }),
  mounted() {
    this.width = window.innerWidth;
    window.addEventListener("resize", function (e) {
      this.width = e.target.innerWidth;
    });
  },
  computed: {
    isJwt() {
      return localStorage.getItem("access_token");
    },
  },
  methods: {
    logout() {
      localStorage.removeItem("access_token");
      location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
nav {
  height: 70px;

  * {
    color: rgba(0, 0, 0, 0.7);
  }
}

a,
button {
  display: flex;
  align-items: center;

  margin-left: 10px;
  font-size: 14px;
  font-weight: 600;

  img {
    margin-right: 5px;
  }
}

a:first-child {
  margin-left: 0;
}

.logo {
  height: 25px;
}

.hidden {
  display: none;
}

@media (max-width: 768px) {
  .hidden-768 {
    display: none;
  }
}

@media (max-width: 768px) {
  .show-768 {
    display: block;
  }
}

@media (max-width: 1280px) {
  .hiddenOnMobile {
    display: none !important;
  }
  a,
  button {
    font-size: 12px;
  }
}
</style>

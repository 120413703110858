<template>
  <i class="fa-solid fa-spinner animate-spin"></i>
</template>

<script>
export default {
  name: "Loader",
};
</script>

<style lang="scss" scoped></style>

import { createStore } from "vuex";
import { ApiConnector } from "@/utils/api";

export default createStore({
  state: {
    user: {
      jwtToken: "",
    },
    games: [],
  },
  getters: {},
  mutations: {
    initJwt(state) {
      state.user.jwtToken = `Bearer ` + localStorage.getItem("access_token");
    },
    setUserData(state, data) {
      state.user = { ...state.user, ...data };
    },
    setGames(state, data) {
      state.games = data;
    },
    addNewGame(state, data) {
      state.games.push(data);
    },
  },
  actions: {
    async getStatus({ commit }) {
      const user = await ApiConnector.getStatus();

      console.log(user);
      commit("setUserData", user);
    },
    async getMyGames({ commit }) {
      const games = await ApiConnector.getMyGames();

      commit("setGames", games);
    },
  },
  modules: {},
});

export const clearIntervals = () => {
  // Get a reference to the last interval + 1
  const interval_id = window.setInterval(function () {},
  Number.MAX_SAFE_INTEGER);

  // Clear any timeout/interval up to that id
  for (let i = 1; i < interval_id; i++) {
    window.clearInterval(i);
  }
};

export const Languages = [
  { name: "en", value: "English" },
  { name: "es", value: "Spanish" },
  { name: "fr", value: "French" },
  { name: "de", value: "German" },
  { name: "it", value: "Italian" },
  { name: "zh", value: "Chinese" },
  { name: "ja", value: "Japanese" },
  { name: "ko", value: "Korean" },
  { name: "pt", value: "Portuguese" },
  { name: "ua", value: "Ukrainian" },
  { name: "ru", value: "Russian" },
];

export const Subjects = [
  "Science",
  "Mental Health",
  "Computer Science",
  "Geography",
  "History",
  "Languages",
  "English",
  "Maths",
  "Art",
  "Technologies",
  "Physical Health",
  "Music",
];

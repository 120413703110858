<template>
  <nav class="flex bg-[#1088C5] items-center">
    <div class="container flex justify-between items-center">
      <div class="flex gap-2">
        <button
          v-for="c in content"
          @click="$router.push(c.href)"
          :key="c.text"
          class="flex cursor-pointer relative flex-col justify-end"
        >
          <img
            class="h-[60px] w-[150px] object-cover rounded-lg"
            :src="c.img"
            alt="img"
          />
          <span
            class="text-[12px] rounded-b-lg absolute w-full bg-black text-gray-200"
            >{{ c.text }}</span
          >
        </button>
      </div>

      <div class="navbar__items flex gap-2">
        <a
          v-if="isJwt"
          href="https://wordrush.org/admin/profile"
          class="shadow flex flex-col gap-1 hover:bg-sky-400 hover:text-white transition bg-white text-black rounded-md p-2"
        >
          <span
            class="bg-sky-400 rounded-md text-lg flex items-center justify-center p-2"
          >
            <i class="fa-solid fa-book text-white"></i>
          </span>
          My student data
        </a>

        <a
          href="https://wordrush.org#powerpoint"
          class="shadow flex flex-col gap-1 hover:bg-sky-400 hover:text-white transition bg-white text-black rounded-md p-2"
        >
          <span
            class="bg-sky-400 rounded-md text-lg flex items-center justify-center p-2"
          >
            <i class="fa-solid fa-plus text-white"></i>
          </span>
          Create new game
        </a>

        <a
          href="https://wordrush.org/join"
          class="shadow flex flex-col gap-1 hover:bg-sky-400 hover:text-white transition bg-white text-black rounded-md p-2"
        >
          <span
            class="bg-sky-400 rounded-md text-lg flex items-center justify-center p-2"
          >
            <i class="fa-solid fa-gamepad text-white"></i>
          </span>
          Join a game
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "TargetNavbar",
  components: {},
  computed: {
    isJwt() {
      return localStorage.getItem("access_token");
    },
  },
  data: () => ({
    content: [
      {
        href: "/solo/The%20Unseen%20Risks%20of%20Vaping?id=66761789047679df2a021d20",
        img: "https://api.wordrush.org/uploads/2024-06-22/A_high school settin-1719015106828.webp",
        text: "Vaping",
        w: "89px",
      },
      {
        href: "/solo/Legacy%20of%20Ramses%20II?id=667613f6047679df2a021cca",
        img: "https://api.wordrush.org/uploads/2024-06-21/A_panoramic Egyptian-1719014195582.webp",
        text: "Legacy of Ramses II",
        w: "117px",
      },
      {
        href: "/solo/The%20Ethical%20Hacker's%20Path?id=66761d85047679df2a021d5d",
        img: "https://api.wordrush.org/uploads/2024-06-22/A_teenager seated at-1719016641304.webp",
        text: "Cyber Security",
        w: "92px",
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
nav {
  padding: 5px 20px;
}

.hidden {
  display: none;
}

@media (max-width: 768px) {
  .hidden-768 {
    display: none;
  }
}

@media (max-width: 768px) {
  .show-768 {
    display: block;
  }
}

@media (max-width: 1280px) {
  .hiddenOnMobile {
    display: none !important;
  }
  a,
  button {
    font-size: 12px;
  }
}

.navbar__items {
  font-weight: 600;
  color: #6a706c;
}
</style>

<template>
  <div
    ref="sidebar"
    :class="`${isShowSidebar ? 'translate-x-0' : 'translate-x-full'}`"
    :style="{ width: width, background: bg }"
    class="sidebar fixed z-[2] top-0 bottom-0 right-0 transition-all"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    bg: {
      type: String,
    },
    width: {
      type: String,
      default: "100%",
    },
    isShowSidebar: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  background: rgba(88, 194, 232, 0.8);
}
</style>

import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/test",
    name: "test",
    component: () => import("@/views/TestView.vue"),
  },
  {
    path: "/game/:id",
    name: "game-page",
    component: () => import("@/views/GameView.vue"),
  },
  {
    path: "/gamepage/:id",
    name: "GamePageView",
    component: () => import("@/views/GamePageView.vue"),
  },
  {
    path: "/sign-up",
    name: "sign-up",
    component: () => import("@/views/Authorization/SignUpView.vue"),
  },
  {
    path: "/sign-in",
    name: "sign-in",
    component: () => import("@/views/Authorization/SignInView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

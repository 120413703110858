<template>
  <router-view></router-view>
</template>

<script>
export default {
  data: () => ({}),
  methods: {},
  mounted() {
    this.$store.commit("initJwt");
    this.$store.dispatch("getStatus");
  },
  watch: {},
  computed: {},
};
</script>

<style lang="scss" scoped></style>

<template>
  <div
    v-if="popupVideo"
    @click="$emit('close')"
    class="fixed top-0 bg-black z-[1000] bg-opacity-30 left-0 w-full h-full flex items-center justify-center backdrop-blur-sm"
  >
    <iframe
      @click.prevent
      class="w-[80%] h-[80%]"
      :src="popupVideo"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerpolicy="strict-origin-when-cross-origin"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
export default {
  props: {
    popupVideo: {
      type: String,
    },
  },
  data: () => ({}),
  methods: {},
  watch: {},
  computed: {},
};
</script>

<style lang="scss" scoped></style>

<template>
  <button
    @click="playGame"
    @mouseleave="isHovered = false"
    @mouseover="isHovered = true"
    class="block relative p-6 bg-white overflow-hidden border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
  >
    <h5
      class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white"
    >
      <slot name="title" />
    </h5>
    <p class="font-normal text-gray-700 dark:text-gray-400">
      <slot name="desc" />
    </p>

    <span
      v-if="game.subject"
      class="px-2 font-semibold text-[12px] absolute right-2 top-2 rounded-[20px] py-0.5 bg-gradient-to-tr from-fuchsia-600 to-sky-500 text-white"
    >
      {{ game.subject }}
    </span>

    <span
      class="tags w-full mt-4 flex gap-2 items-center text-sm text-white right-2 top-2"
    >
      <span
        class="px-2 font-semibold text-[12px] py-0.5 rounded-[20px]"
        :style="{ color: colors[i], backgroundColor: bgColors[i] }"
        v-for="(t, i) in game.tags"
        :key="t"
      >
        {{ t }}
      </span>
    </span>

    <div
      :class="isHovered ? 'opacity-100 h-full w-full' : 'opacity-0 h-0 w-0'"
      class="absolute flex items-center justify-center transition-all duration-300 transition-gpu top-0 left-0 bg-blue-200"
    >
      <i class="fa-solid fa-play text-blue-700 text-3xl"></i>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    game: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isHovered: false,
    colors: {
      0: "#17246e",
      1: "#1c8c53",
      2: "#6c2191",
    },
    bgColors: {
      0: "#628cd9",
      1: "#7de166",
      2: "#c072e7",
    },
  }),
  methods: {
    async playGame() {
      if (this.disabled) return;
      this.$emit("setCreatingGame");
      this.$router.push("/gamepage/" + this.game._id);
      // const game = await ApiConnector.playGame(this.game._id);
      //
      // location.href = "/game/" + game.pin;
    },
  },
  watch: {},
  computed: {},
};
</script>

<style lang="scss" scoped></style>
